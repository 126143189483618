import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const imageApi = createApi({
    reducerPath: 'imageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers) => {
            const token = process.env.REACT_APP_API_TOKEN;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchImage: builder.query({
            query: (imageName) => `/api/upload/files/${imageName}`,
        }),
    }),
});

export const { useFetchImageQuery } = imageApi;
export { imageApi };
// src/contexts/PlausibleContext.js
import React, { createContext, useContext, useMemo } from 'react';
import Plausible from 'plausible-tracker';

const PlausibleContext = createContext(null);

export const usePlausible = () => useContext(PlausibleContext);

export const PlausibleProvider = ({ children }) => {
    const plausible = useMemo(() => Plausible({
        domain: process.env.REACT_APP_PLAUSIBLE_DOMAIN,
        trackLocalhost: process.env.REACT_APP_PLAUSIBLE_TRACK_LOCALHOST === 'true',
        hashMode: true, // URL-hash wijzigingen tracken indien nodig
        apiHost: 'https://plausible.io' // Pas aan als je self-hosting gebruikt
    }), []);

    return (
        <PlausibleContext.Provider value={plausible}>
            {children}
        </PlausibleContext.Provider>
    );
};
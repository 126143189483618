import React from 'react';
import Header from './Header';
import Footer from './Footer';

function Layout({ children }) {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
                <main className="flex-grow px-0 py-0">
                    {children}
                </main>
            <Footer />
        </div>
    );
}

export default Layout;

import React from 'react';
import PlausibleTracker from "../components/Plausible/PlausibleTracker";

function AboutPage() {


    return (
        <div>
            <PlausibleTracker/>
           Over ons pagina
        </div>
    );
}

export default AboutPage;

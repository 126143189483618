import React from 'react';
import Artwork from "../components/ArtWork/ArtWork";
import PlausibleTracker from "../components/Plausible/PlausibleTracker";


function ArtWorkPage() {

    return (
        <div>
            <PlausibleTracker/>
            <Artwork/>
        </div>
    );
}

export default ArtWorkPage;

import React from 'react';

// Helperfunctie om stijlklasse te genereren op basis van tekstattributen
const generateStyleClasses = (child) => {
    let styleClasses = "";
    if (child.bold) styleClasses += " font-bold";
    if (child.italic) styleClasses += " italic";
    if (child.underline) styleClasses += " underline";
    if (child.strikethrough) styleClasses += " line-through";
    return styleClasses;
};

// Functie om de geneste kinderen van een item te renderen
const renderChildren = (children, parentKey) => {
    return children.map((child, index) => {
        const styleClasses = generateStyleClasses(child);
        // Een unieke key genereren op basis van het type kind, de parent key (indien gegeven), en de index van het kind.
        const key = parentKey ? `${parentKey}-${child.type}-${index}` : `${child.type}-${index}`;

        switch (child.type) {
            case 'text':
                // De 'key' voor 'text' componenten is gebaseerd op hun positie en type.
                return <span key={key} className={styleClasses}>{child.text}</span>;
            case 'link':
                // Voor 'link' componenten voegen we het type en de index toe aan de key.
                // Bij het recursief aanroepen van renderChildren voor child.children, geven we de 'key' door als 'parentKey'.
                return (
                    <a key={key} href={child.url} className={`${styleClasses} text-black hover:text-gray-700 underline`} aria-label="Read more">
                        {child.children ? renderChildren(child.children, key) : null}
                    </a>
                );
            default:
                return null;
        }
    });
};


const renderContentItem = (item, index) => {
    const baseKey = `${item.type}-${index}`;
    switch (item.type) {
        case 'paragraph':
            const hasMeaningfulContent = item.children.some(child => child.type !== 'text' || child.text.trim() !== '');
            if (!hasMeaningfulContent) {
                // Voeg een unieke key toe aan de paragraaf, zelfs voor witregels
                return <p key={`${baseKey}-space`} className="">&nbsp;</p>;
            }
            // Gebruik baseKey voor een unieke sleutel
            return <p key={baseKey} className="mb-0">{renderChildren(item.children, baseKey)}</p>;

        case 'heading':
            const HeadingTag = `h${item.level}`;
            // Gebruik baseKey voor een unieke sleutel
            return <HeadingTag key={baseKey}>{renderChildren(item.children, baseKey)}</HeadingTag>;

        case 'list':
            const ListTag = item.format === 'ordered' ? 'ol' : 'ul';
            // Gebruik baseKey voor een unieke sleutel en zorg voor uniciteit in geneste lijstitems
            return (
                <ListTag key={baseKey} className={`${item.format === 'ordered' ? 'list-decimal' : 'list-disc'} list-inside`}>
                    {item.children.map((li, idx) => (
                        // Voeg de lijstitem-index toe aan de baseKey voor lijstitems om uniciteit te verzekeren
                        <li key={`${baseKey}-item-${idx}`}>{renderChildren(li.children, `${baseKey}-item-${idx}`)}</li>
                    ))}
                </ListTag>
            );

        case 'quote':
            // Gebruik baseKey voor een unieke sleutel
            return <blockquote key={baseKey} className="border-l-4 border-gray-500 italic my-4 pl-4">{renderChildren(item.children, baseKey)}</blockquote>;

        case 'image':
            // Gebruik baseKey voor een unieke sleutel
            return <img key={baseKey} src={item.image.url} alt={item.image.alternativeText || 'image'} className="max-w-full h-auto my-4" />;

        default:
            return null;
    }
};

const GenericText = ({ headline, content }) => (
    <div className='content-block'>
        {headline && <h1>{headline}</h1>}
        <div>
            {content.map((block, index) => renderContentItem(block, index))}
        </div>
    </div>
);

export default GenericText;
import React from 'react';
import ReactDom from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store';
import './input.css';

import App from "./App";
import ErrorPage from "./Routes/ErrorPage";
import AboutPage from "./Routes/AboutPage";
import ContactPage from "./Routes/ContactPage";
import PrivacyPolicyPage from "./Routes/PrivacyPolicyPage";
import ArtWorksPage from "./Routes/ArtWorksPage";
import ArtWorkPage from "./Routes/ArtWorkPage";
import {PlausibleProvider} from "./Contexts/PlausibleContext";
import LandingPage from "./Routes/LandingPage";

const el = document.getElementById('root');
const root = ReactDom.createRoot(el);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,  // Dit specificeert dat dit de standaard subroute is voor "/"
                element: <LandingPage/>,
            },
            {
                path: "artworks",
                element: <ArtWorksPage/>,
            },
            {
                path: "artworks/:slug",
                element: <ArtWorkPage/>,
            },
            {
                path: "about",
                element: <AboutPage/>,
            },
            {
                path: "contact",
                element: <ContactPage/>,
            },
            {
                path: "privacy-policy",
                element: <PrivacyPolicyPage/>,
            }
        ],
    },
]);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PlausibleProvider>
                <RouterProvider router={router} />
            </PlausibleProvider>
        </Provider>
    </React.StrictMode>
);
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const menuApi = createApi({
    reducerPath: 'menus',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
    }),
    endpoints(builder) {
        return {
            fetchMenu: builder.query({
                query: (slug) => { // 'slug' is de variabele die het betreffende menu bepaald.
                    return {
                        url: `/api/menus/${slug}`, // Gebruik de 'slug' variabele om de URL te bepalen
                        method: 'GET',
                    };
                },
            }),
        };
    },
});

export const { useFetchMenuQuery } = menuApi;
export { menuApi };
import React from 'react';
import ArtWorksGrid from "../components/ArtWorks/ArtWorksGrid";
import PlausibleTracker from "../components/Plausible/PlausibleTracker";

function ArtWorksPage() {



    return (
        <div>
            <PlausibleTracker />
            <ArtWorksGrid/>
        </div>
    );
}

export default ArtWorksPage;

import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon, ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

const Breadcrumbs = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <nav className="flex items-center text-neutral-500 text-sm">
            <button
                onClick={() => navigate(-1)}
                className="hidden md:inline-flex items-center justify-center text-purple hover:text-neutral-700 border border-purple hover:border-neutral-700 rounded px-2 py-1 mr-4"
                aria-label="Terug">
                <ChevronLeftIcon className="h-4 w-4 mr-1" />
                <span>Terug</span>
            </button>
            <div className="flex items-center">
                <Link to="/" aria-label="Home">
                    <HomeIcon className="h-5 w-5 hover:text-neutral-700" />
                </Link>
                {pathnames.map((value, index) => {
                    const isLast = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return (
                        <React.Fragment key={to}>
                            <ChevronRightIcon className="h-4 w-4 mx-2" />
                            {isLast ? (
                                <span className='truncate'>{decodeURIComponent(value)}</span>
                            ) : (
                                <Link to={to} className="hover:underline hover:text-neutral-700" aria-label={`Ga naar ${decodeURIComponent(value)}`}>
                                    {decodeURIComponent(value)}
                                </Link>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </nav>
    );
};

export default Breadcrumbs;

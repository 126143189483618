import { useRouteError } from "react-router-dom";
import React from "react";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    // Conditioneel bericht gebaseerd op de beschikbare error data
    const errorMessage = error?.statusText || error?.message || "An unexpected error has occurred.";

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            {/* Render alleen de error message als deze bestaat */}
            <p><i>{errorMessage}</i></p>
        </div>
    );
}

import React from 'react';
import { useParams } from 'react-router-dom';
import { useFetchArtWorkBySlugQuery } from "../../store";
import SiteTitleComponent from "../Layout/Components/siteTitleComponent";
import GenericText from "../DynamicPage/GenericText/GenericText";
import Loader from "../Loader/Loader";
import ImageLoader from "../ImageLoader/ImageLoader";
import Main from "../Layout/Main";

const Artwork = () => {
    const { slug } = useParams();
    const { data, error, isLoading } = useFetchArtWorkBySlugQuery(slug);

    if (isLoading) return <div><Loader /></div>;
    if (error) return <div>An error occurred: {error.toString()}</div>;

    const artwork = data?.data[0]?.attributes;

    return (
        artwork ? (

            <Main>
                <SiteTitleComponent title={artwork.headline} />


                <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 sm:gap-10 md:gap-16 lg:gap-24' >
                    <div className='flex flex-col sm:h-[calc(100vh-250px)] align-top'>
                        <ImageLoader
                            imageData={artwork.featuredImage?.data?.attributes}
                            format="large" // Je kunt 'thumbnail', 'small', 'medium', of 'large' als formaat kiezen
                        />
                    </div>

                    <div className=''>
                        <h1 className='text-4xl font-extralight uppercase'>{artwork.headline}</h1>
                        {artwork.quotes &&
                            <div className='my-10 py-2'>
                                <div className='text-sm font-extralight'><GenericText content={artwork.quotes}/></div>
                            </div>
                        }

                        {artwork.material && artwork.width && artwork.height && artwork.copyrightYear &&
                            <div className='my-16 border-t border-b py-2'>
                                <p className='text-sm font-extralight'>{artwork.material}</p>
                                {artwork.width && artwork.height && (
                                    <p className='text-sm font-extralight'>{artwork.height} x {artwork.width} cm (h x b)</p>
                                )}
                                <p className='text-sm font-extralight'>{artwork.copyrightYear}</p>
                            </div>
                        }
                    </div>

                </div>

            </Main>
        ) : <div>Kunstwerk niet gevonden</div>
    );
};

export default Artwork;

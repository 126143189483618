import React from 'react';

import HorizontalBar from "./HorizontalBar/HorizontalBar";
import GenericText from "./GenericText/GenericText";
import FatText from "./FatText/FatText";
import HeroBanner from "./HeroBanner/HeroBanner";

// Mapping van __component waarden naar React componenten
const componentMapping = {
    'generiek.tekst': GenericText,
    'generiek.horizontale-balk': HorizontalBar,
    'generiek.fat-tekst': FatText,
    'generiek.hero-banner': HeroBanner
};

const DynamicPage = ({ data }) => {
    const renderComponent = (componentData, index) => {
        const Component = componentMapping[componentData.__component];
        if (!Component) {
            console.warn(`Geen component gevonden voor type: ${componentData.__component}`);
            return null;
        }
        // Gebruik een combinatie van id en index als key
        const key = `${componentData.__component}-${componentData.id || index}`;
        return <Component key={key} {...componentData} />;
    };

    return (
        <div>
            {data.attributes.content.map(renderComponent)}
        </div>
    );
};

export default DynamicPage;

import React from 'react';
import Breadcrumbs from "./Components/Breadcrumbs";

function Main({ children }) {
    return (
        <div className="flex-grow container px-4 mx-auto mt-4 sm:mt-6 md:mt-6 lg:mt-8" >
            <Breadcrumbs/>
            <div className='my-4 sm:my-6 md:my-7 lg:my-8'>
                {children}
            </div>

        </div>
    );
}

export default Main;

import React from 'react';
import { useFetchSingleTypesQuery} from "../store";
import PlausibleTracker from "../components/Plausible/PlausibleTracker";
import ErrorPage from "./ErrorPage";
import DynamicPage from "../components/DynamicPage/DynamicPage";



function LandingPage() {

    const { data, error, isLoading } = useFetchSingleTypesQuery(`ocg1-landing-page?populate[content][populate]=*`);

    if (isLoading) return <div className="text-center"></div>;
    if (error || !data) return <ErrorPage />;

    return (
        <div className="w-full">
            <PlausibleTracker/>
            <DynamicPage data={data.data}/>
        </div>
    );
}

export default LandingPage;

import React from 'react';
import { ReactComponent as Logo } from '../../assets/logo_ocg1.svg'
import Menu from "./Menu";
import {Link} from "react-router-dom";

function Header() {
    return (
        <header className="bg-purple h-20 flex items-center text-white text-xl font-extralight px-4 border-b-2 border-[#D2FF03]">

            <Link to={'/'}>
               <div className='flex flex-row items-center'>
                   <Logo className="h-20 w-20 mr-3" /> One Cloud Gallery 1
               </div>
            </Link>
            <Menu/>
        </header>
    );
}

export default Header;

import React from 'react';
import ButtonStandard from "../Buttons/ButtonStandard";
import BackgroundImageLoader from "../../ImageLoader/BackgroundImageLoader";

function HeroBanner({ Title, subTitle, featuredImage, button }) {

    return (
        <BackgroundImageLoader imageData={featuredImage?.data?.attributes}>

            <div className="text-left p-2 md:p-10 lg:p-20 w-full mb-16">
                <div className="
                    text-white
                    font-bold
                    tracking-normal
                    text-6xl md:text-7xl lg:text-8xl
                    drop-shadow-md">
                    {Title}
                </div>
                <div className="
                    text-white
                    font-normal
                    text-xl md:text-4xl lg:text-5xl
                    mt-4
                    drop-shadow-md">
                    {subTitle}
                </div>
                <div className='flex flex-wrap md:flex-row mt-6 space-y-4 md:space-x-4 md:space-y-0'>
                    {button.map(btn => (
                        <ButtonStandard
                            buttonText={btn.buttonText}
                            to={btn.link}
                            buttonLabel={btn.label}
                            type={btn.type}
                            key={btn.id}
                            target={btn.target}
                        />
                    ))}
                </div>
            </div>
        </BackgroundImageLoader>
    );
}

export default HeroBanner;

import React from 'react';

const Loader = () => {
    return (
        <div className="flex justify-center items-center h-screen h-[150px]"> {/* Deze div neemt de hele schermhoogte in beslag */}
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-neutral-400"></div> {/* Pas de kleur en grootte naar wens aan */}
        </div>
    );
};

export default Loader;

import React, { useState, useEffect } from 'react';
import { useFetchMenuQuery } from "../../store/apis/menuApi";
import { Bars4Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as Logo } from '../../assets/logo_ocg1.svg'

function Menu() {
    const [isOpen, setIsOpen] = useState(false);
    const { data, error, isLoading } = useFetchMenuQuery('2?populate=*');

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);

    if (isLoading) return <div className="fixed inset-0 flex justify-center items-center bg-purple z-40">Loading...</div>;
    if (error) return <div className="fixed inset-0 flex justify-center items-center bg-purple z-40">Error: {error.message}</div>;

    const menuItems = data?.data.attributes.items.data
        .slice()
        .sort((a, b) => a.attributes.order - b.attributes.order);

    return (
        <nav className="z-10">
            {/* Icoon/Menu-knop */}
            <button
                className="absolute top-6 right-8 text-3xl z-20" // This positions the button at the top right
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                aria-controls="menu"
            >
                {isOpen ? <XMarkIcon className='h-8 w-8 z-30 fixed right-8'/> : <Bars4Icon className='h-8 w-8'/>}
            </button>

            {/* Menu Overlay */}
            <div
                id="menu"
                className={`fixed inset-0 bg-purple transition-opacity duration-300 ease-out ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                aria-hidden={!isOpen}
            >
                <div className='grid grid-cols-1 sm:grid-cols-2 place-items-center h-full'>

                    {/* Linker menu */}
                    <div className='w-full'>
                        <div className='w-4/5'>
                            <div className='flex flex-col items-end'>
                                <div className='flex flex-col items-center'> {/* Centrale container voor het logo en de tekst */}
                                    <Logo className="h-28 w-28 sm:h-36 sm:w-36"/> {/* Logo in het midden */}
                                    <h1 className="text-2xl text-white font-light text-center">One Cloud Gallery 1</h1> {/* Tekst in het midden */}
                                </div>

                                {/* Balk 1 */}
                                <div className='flex flex-row h-1.5 sm:h-3 mt-4 mb-4 w-full'>
                                    <div className='flex flex-row w-1/3'>
                                        <div className='w-4/5 bg-green'/>
                                        <div className='w-1/5'/>
                                    </div>
                                    <div className='flex flex-row w-2/3'>
                                        <div className='w-1/2 bg-white'/>
                                        <div className='w-1/2 bg-green'/>
                                    </div>
                                </div>




                                {menuItems && (
                                    <div className="flex flex-col items-end space-y-4">
                                        {menuItems.map((item) => (
                                            <a key={item.attributes.title} href={item.attributes.url} className="text-3xl sm:text-6xl font-light sm:font-thin lowercase" target={item.attributes.target} rel="noopener noreferrer" onClick={() => setIsOpen(false)}>{item.attributes.title}</a>
                                        ))}
                                    </div>
                                )}

                                {/* Balk 2 */}
                                <div className='flex flex-row h-1.5 sm:h-3 mt-4 mb-10 w-full'>
                                    <div className='flex flex-row w-1/3'/>
                                    <div className='flex flex-row w-2/3'>
                                        <div className='w-1/2 bg-white'/>
                                        <div className='w-1/2 bg-green'/>
                                    </div>
                                </div>

                                {/* Balk 3 */}
                                <div className='flex flex-row h-1.5 sm:h-3 my-0 w-full'>
                                    <div className='flex flex-row w-1/3'>
                                        <div className='w-4/5 bg-green'/>
                                        <div className='w-1/5'/>
                                    </div>
                                    <div className='flex flex-row w-2/3'/>
                                </div>

                                {/* Balk 4 */}
                                <div className='flex flex-row h-1.5 sm:h-3 my-0 w-full'>
                                    <div className='flex flex-row w-1/3'>
                                        <div className='w-4/5'/>
                                        <div className='w-1/5 bg-white'/>
                                    </div>
                                    <div className='flex flex-row w-2/3'/>
                                </div>
                            </div>






                        </div>


                    </div>

                    {/* Rechter menu */}
                    <div className='hidden md:block md:text-center md:space-y-4 md:items-end'>
                        <p className='text-3xl italic text-white font-light'>
                            "We are spiritual beings, we have to accept this, once again"
                        </p>
                        <p className='text-2xl italic text-white text-right font-extralight'>
                            William Otermans
                        </p>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Menu;

import React, { useState, useEffect } from 'react';

const BackgroundImageLoader = ({ imageData, children, format = 'full' }) => {
    const [loaded, setLoaded] = useState(false);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (imageData && imageData.formats && imageData.formats[format]) {
            setBackgroundImageUrl(baseUrl + imageData.formats[format].url);
        } else if (imageData && imageData.url) {
            setBackgroundImageUrl(baseUrl + imageData.url);
        }
    }, [imageData, format, baseUrl]);

    return (
        <div className={`w-full h-[calc(100vh-80px)] bg-cover bg-center flex flex-col justify-center items-start border-b-2 border-[#D2FF03] ${
            loaded ? 'bg-loaded' : 'bg-loading'
        }`}
             style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
            {!loaded && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-neutral-400"></div>
                </div>
            )}
            <img
                src={backgroundImageUrl}
                alt={imageData?.alternativeText || 'Background image'}
                onLoad={() => setLoaded(true)}
                className="hidden" // The image element is hidden, only used to handle the load event
            />
            {loaded && children}
        </div>
    );
};

export default BackgroundImageLoader;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePlausible} from "../../Contexts/PlausibleContext";

const PlausibleTracker = ({ options = {} }) => {
    const location = useLocation();
    const plausible = usePlausible();

    useEffect(() => {
        // Verstuur een pageview naar Plausible wanneer de locatie verandert
        plausible.trackPageview();
    }, [location.pathname, plausible]); // Voeg 'options' toe aan de dependencies array

    return null; // Dit component heeft geen visuele output
};

export default PlausibleTracker;

import React from 'react';


function Footer() {
    return (
        <footer className="bg-gray-500 text-white p-1 text-center font-light text-xs">
            &copy; 2024 One Cloud Gallery 1 - all rights reserved.
        </footer>
    );
}

export default Footer;

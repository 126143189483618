import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { artWorksApi } from "./apis/artWorksApi";
import { singleTypeApi } from "./apis/singleTypeApi";
import { menuApi } from "./apis/menuApi";
import { imageApi } from "./apis/imageApi";

export const store = configureStore({
    reducer: {
        [artWorksApi.reducerPath]: artWorksApi.reducer,
        [singleTypeApi.reducerPath]: singleTypeApi.reducer,
        [menuApi.reducerPath]: menuApi.reducer,
        [imageApi.reducerPath]: imageApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(artWorksApi.middleware)
            .concat(singleTypeApi.middleware)
            .concat(menuApi.middleware)
            .concat(imageApi.middleware),
});

// Initialiseer automatisch refetching voor 'inactive' queries en het pollen van queries
setupListeners(store.dispatch);

// Exporteer hooks voor elke API
export { useFetchArtWorksQuery, useFetchArtWorkBySlugQuery } from './apis/artWorksApi';
export { useFetchSingleTypesQuery } from './apis/singleTypeApi';
export { useFetchMenuQuery } from './apis/menuApi';
export { useFetchImageQuery } from './apis/imageApi';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const artWorksApi = createApi({
    reducerPath: 'artWorks',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
    }),
    endpoints(builder) {
        return {
            fetchArtWorks: builder.query({
                query: () => '/api/artworks?populate=*&pagination[page]=1&pagination[pageSize]=80',
            }),
            fetchArtWorkBySlug: builder.query({
                query: (slug) => `/api/artworks?filters[slug][$eq]=${slug}&populate=*`,
            }),
        };
    },
});

export const { useFetchArtWorksQuery, useFetchArtWorkBySlugQuery } = artWorksApi;
export { artWorksApi };

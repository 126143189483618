import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const singleTypeApi = createApi({
    reducerPath: 'singleType',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
    }),
    endpoints(builder) {
        return {
            fetchSingleTypes: builder.query({
                query: (slug) => { // 'slug' is de variabele die de URL bepaalt
                    return {
                        url: `/api/${slug}`,
                        method: 'GET',
                    };
                },
            }),
        };
    },
});

export const { useFetchSingleTypesQuery } = singleTypeApi;
export { singleTypeApi };
import React from 'react';
import { Link } from 'react-router-dom';
import { usePlausible } from "../../../Contexts/PlausibleContext";

const ButtonStandard = ({ id, buttonText, to, buttonLabel, target, type }) => {
    const plausible = usePlausible();

    const handleClick = () => {
        // Stuur event naar Plausible bij klik
        plausible.trackEvent('Button clicked', {
            props: {
                buttonLabel: 'Button: ' + buttonLabel,
                buttonText: buttonText,
                page: window.location.pathname,
            }
        });
    };

    // Conditioneel toepassen van klassen op basis van type
    const buttonClass = type === "Outline"
        ? "inline-flex items-center justify-center font-medium w-64 h-14 border-2 border-[#D2FF03] text-[#D2FF03] bg-transparent text-lg rounded-md shadow-md hover:text-black hover:bg-[#D2FF03] transition-colors duration-300 ease-in-out"
        : "inline-flex items-center justify-center font-medium w-64 h-14 bg-[#D2FF03] text-black text-lg rounded-md shadow-md hover:bg-[#bce025] transition-colors duration-300 ease-in-out";

    return (
        <Link to={to}
              target={target}
              key={id}
              className={buttonClass}
              onClick={handleClick}>
            {buttonText}
        </Link>
    );
};

export default ButtonStandard;

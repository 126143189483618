import React from 'react';
import { useFetchSingleTypesQuery} from "../store";
import PlausibleTracker from "../components/Plausible/PlausibleTracker";
import ErrorPage from "./ErrorPage";
import DynamicPage from "../components/DynamicPage/DynamicPage";
import Main from "../components/Layout/Main";

function ContactPage() {

    const { data, error, isLoading } = useFetchSingleTypesQuery(`ocg1-contact?populate=*`);

    if (isLoading) return <div className="text-center"></div>;
    if (error || !data) return <ErrorPage/>;

    return (
        <Main>
            <PlausibleTracker/>
            <DynamicPage data={data.data}/>
        </Main>
    );
}

export default ContactPage;

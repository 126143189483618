import React from 'react';

const FatText = ({ content }) => {
    // Splits de content op newline karakters om een array van regels te krijgen
    const lines = content.split('\n').map((line, index) => (
        // Voor elke regel, maak een div of span. Voeg een key toe voor unieke identificatie.
        <div key={index} className='font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl lowercase'>
            {line}
        </div>
    ));

    return (
        <div className='content-block'>
            {lines}
        </div>
    );
};

export default FatText;

import React from 'react';
import PlausibleTracker from "../components/Plausible/PlausibleTracker";

function PrivacyPolicyPage() {


    return (
        <div>
            <PlausibleTracker/>
           Privacy Policy
        </div>
    );
}

export default PrivacyPolicyPage;

import React, { useEffect, useState } from 'react';
import { useFetchArtWorksQuery } from "../../store";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import ImageLoader from "../ImageLoader/ImageLoader";
import Main from "../Layout/Main";

function ArtWorksGrid() {
    const { data: artworks, error, isLoading } = useFetchArtWorksQuery();
    const [scales, setScales] = useState([]);

    useEffect(() => {
        if (artworks) {
            const maxWidth = Math.max(...artworks.data.map(({ attributes }) => attributes.width));
            const scales = artworks.data.map(({ attributes }) => (attributes.width / maxWidth) * 100);
            setScales(scales);
        }
    }, [artworks]);

    if (isLoading) return <Loader />;
    if (error) return <div>Error: {error.message}</div>;
    if (!artworks) return null;

    return (
        <Main>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">
                {artworks.data.map(({ id, attributes }, index) => (
                    <Link to={`/artworks/${attributes.slug}`} key={id} className="flex flex-col self-end">
                        <div style={{ width: `${scales[index]}%`, height: 'auto' }}>
                            <ImageLoader
                                imageData={attributes.featuredImage?.data?.attributes}
                                format="medium"
                            />
                        </div>
                        <h3 className="text-neutral-700 font-light text-lg text-start uppercase mt-2 line-clamp-1">{attributes.headline}</h3>
                        <p className="text-neutral-700 font-light text-sm text-start">{attributes.copyrightYear}</p>
                    </Link>
                ))}
            </div>
        </Main>
    );
}

export default ArtWorksGrid;

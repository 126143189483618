import React, { useState, useEffect } from 'react';

const ImageLoader = ({ imageData, format = 'thumbnail' }) => {
    const [loaded, setLoaded] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (imageData && imageData.formats && imageData.formats[format]) {
            setImageUrl(baseUrl + imageData.formats[format].url);
        } else if (imageData && imageData.url) {
            setImageUrl(baseUrl + imageData.url);
        }
    }, [imageData, format, baseUrl]);

    return (
        <div className="flex justify-center items-start w-full h-full">
            {!loaded && (
                <div className="absolute inset-0 flex justify-center items-center">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-neutral-400"></div>
                </div>
            )}
            <img
                src={imageUrl}
                alt={imageData?.alternativeText || 'Afbeelding'}
                onLoad={() => setLoaded(true)}
                className={`${loaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 ease-in-out object-contain h-full drop-shadow-lg`}
            />
        </div>
    );
};

export default ImageLoader;
import { useEffect } from 'react';

// De SiteTitle component neemt de nieuwe titel als een prop.
const SiteTitleComponent = ({ title }) => {

    const siteTitle = 'One Cloud Gallery 1 - '+title;

    useEffect(() => {
        // Bewaar de huidige titel om later te kunnen herstellen.
        const originalTitle = document.title;

        // Update de paginatitel met de nieuwe titel.
        document.title = siteTitle;

        // Cleanup functie die de originele titel herstelt bij unmount.
        return () => {
            document.title = originalTitle;
        };
    }, [siteTitle]); // Dependency array met 'title' zorgt ervoor dat de titel bijgewerkt wordt als de prop verandert.

    return null;
};

export default SiteTitleComponent;
